import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/projects/wizartsoft.com/src/views/mdxPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`About`}</h3>
    <p>{`Wizart DCC Platform was created based on the ten years of experience of the R&D development of the company founders.`}</p>
    <p>{`Our mission is to create and improve open-source software for the needs of animation studios or other Media and Entertainment companies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      